/* Header container styling */
.app__header {
    margin-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-bg);
    transition: margin 0.3s ease;
}

@media only screen and (min-width: 1024px) {
    .app__header {
        margin-top: -200px;
        margin-bottom: -100px;
        flex-direction: row; /* Row layout for desktop */
    }
}

/* Wrapper for info section */
.app__wrapper_info {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1rem; /* Space between elements */
}

/* Centered heading */
.app__header-h1 {
    font-family: var(--font-base);
    color: var(--color-text-title);
    font-weight: 700;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    font-size: 40px;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 1s forwards; /* Fade-in animation */
    transition: font-size 0.3s ease;
}

/* Responsive font sizing */
@media screen and (min-width: 2000px) {
    .app__header-h1 {
        font-size: 180px;
        line-height: 210px;
    }
}

@media screen and (max-width: 1150px) {
    .app__header-h1 {
        font-size: 90px;
    }
}

@media screen and (max-width: 650px) {
    .app__header-h1 {
        font-size: 70px;
        line-height: 87px;
    }
}

@media screen and (max-width: 450px) {
    .app__header-h1 {
        font-size: 50px;
        line-height: 70px;
    }
}

/* Paragraph styling */
.p__opensans {
    margin: 2rem 0;
    color: #575756;
    font-size: 16px;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 1s forwards;
    animation-delay: 0.2s; /* Delay for staggered effect */
}

/* Centered button styling */
.custom__button {
    color: #353634;
    padding: 1rem 2rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    align-self: center; /* Center the button within the container */
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.custom__button:hover {
    background-color: #9D9D9C;
    transform: scale(1.05);
}

/* Image styling with responsive adjustments */
.app__wrapper_img {
    order: 2;
    opacity: 0;
    transform: scale(1.05);
    animation: fadeIn 1s forwards;
    animation-delay: 0.5s; /* Delay for staggered effect */
}

.app__wrapper_img img {
    width: 80%; /* Keep it proportional to the container */
    height: auto; /* Maintain aspect ratio */
    max-width: 400px; /* Limit width for desktop */
    max-height: 450px; /* Limit height to make it smaller */
    transition: transform 0.3s ease, opacity 0.3s ease;
}

/* Large screen styling for image */
@media only screen and (min-width: 1024px) {
    .app__wrapper_info {
        flex-direction: column;
        order: initial;
    }
}

@media screen and (min-width: 1024px) {
    .app__wrapper_img img {
        width: 60%;
        max-width: 380px; 
        max-height: 400px; 
    }
}

/* Keyframe animations */
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(1.05);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

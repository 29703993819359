.app__navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--color-bg);

    padding: 1rem 2rem;
    margin-bottom: -80px;

}

/* Dropdown styling for desktop */
.app__navbar-dropdown {
    position: relative;
  }
  
  .app__navbar-dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #7b809e; /* Dropdown background */
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    list-style: none;
    padding: 8px 0;
    margin: 0;
    z-index: 100;
  }
  
  .app__navbar-dropdown-menu li {
    padding: 6px 12px; /* Reduced padding for individual options */
    font-size: 13px; /* Adjust font size if necessary */
    line-height: 1.2; /* Tighter line height */
    transition: background-color 0.3s ease;
  }
  
  .app__navbar-dropdown-menu li button {
    background: none;
    border: none;
    color: white;
    font-size: 14px;
    text-align: left;
    width: 100%;
    cursor: pointer;
  }
  
  .app__navbar-dropdown-menu li button:hover {
    color: #fff; /* Hover text color */
  }
  
  .app__navbar-dropdown:hover .app__navbar-dropdown-menu {
    display: block;
  }
  
  /* Dropdown styling for small screens */
  .app__navbar-smallscreen-dropdown {
    list-style: none;
    padding-left: 16px;
    margin: 0;
  }
  
  .app__navbar-smallscreen-dropdown li {
    padding: 6px 12px; /* Reduced padding for individual options */
    font-size: 13px; /* Adjust font size if necessary */
    line-height: 1.2; /* Tighter line height */
    transition: background-color 0.3s ease;
  }
  
  .app__navbar-smallscreen-dropdown li button {
    background: none;
    border: none;
    color: white;
    font-size: 16px;
    text-align: left;
    width: 100%;
    cursor: pointer;
  }
  
  .app__navbar-smallscreen-dropdown li button:hover {
    color: #fff; /* Hover text color */
  }

.app__navbar-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.app__navbar-logo img {
    width: 380px;
}

.app__navbar-links {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    list-style: none;
}

.app__navbar-links li {
    margin: 0 1rem;
    cursor: pointer;
}

.app__navbar-links li:hover {

}

.app__navbar-login {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.app__navbar-login a {
    margin: 0 1rem;
    text-decoration: none;
    transition: .5s ease;
}

.app__navbar-login a:hover {
    border-bottom: 1px solid var(--color-text-title);
}

.app__navbar-login div {
    width: 1px;
    height: 30px;

}

.app__navbar-smallscreen {
    display: none;
}

.app__navbar-smallscreen_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: var(--color-bg);
    transition: .5s ease;

    flex-direction: column;
    z-index: 5;
}

.app__navbar-smallscreen_overlay .overlay__close {
    font-size: 27px;
    color: var(--color-text-title);
    cursor: pointer;

    position: absolute;
    top: 20px;
    right: 20px;
}

.app__navbar-smallscreen_links {
    list-style: none;
}

.app__navbar-smallscreen_links li {
    margin: 2rem;
    cursor: pointer;
    color: var(--color-text-title);
    font-size: 2rem;
    text-align: center;
    font-family: var(--font-base);
}

.app__navbar-smallscreen_links li:hover {
    color: var(--color-text-parag);
}

@media screen and (min-width: 2000px) {
    .app__navbar-logo img {
        width: 210px;
    }
}

@media screen and (max-width: 1150px) {
    .app__navbar-links {
        display: none;
    }

    .app__navbar-smallscreen {
        display: flex;
    }
}

@media screen and (max-width: 650px) {
    .app__navbar {
        padding: 1rem;
    }

    .app__navbar-login {
        display: none;
    }

    .app__navbar-logo img {
        width: 280px;
    }
}
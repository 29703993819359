@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@284&display=swap');

:root {
  --font-base: 'Merriweather', serif;
  --font-alt: 'Merriweather', regular;
  
  --color-text-title: #575756; 
  --color-text-parag: #575756;  
  --color-text-white: #ffff; 
  --color-text-link-hover: #DCCA87; 

  --color-bg: #F5F5F5; 
  --color-bg-button: #A794C6; 
  --color-bg-button-hover: #DCCA87; 
}

* {
  font-family: 'Merriweather', serif;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

a {
  color: #4A4A4A;
  text-decoration: none;
  font-size: 22px;
}

.slide-bottom {
  -webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

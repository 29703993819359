.services-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: #a794c6;
    overflow: hidden;
  }
  
  .coming-soon-text {
    font-size: 3rem; 
    color: #f5f5f5;
    font-weight: bold;
    font-family: 'Merriweather', serif;
    text-align: center;
  }
  
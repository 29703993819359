.app__aboutus {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #353634;
    padding: 50px 20px;
  }
  
  .app__aboutus-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
  }
  
  .app__aboutus-content_about, 
  .app__aboutus-content_history {
    margin-bottom: 40px;
    text-align: center;
  }
  
  .spoon__img {
    max-width: 60px;
    height: auto;
  }
  
  .p__opensans {
    font-size: 18px;
    line-height: 1.6;
    color: #fff;
    max-width: 800px;
    margin: 0 auto;
    text-align: justify;
  }
  
  /* Add Highlighting to Important Words */
  .highlight {
    font-weight: bold;
    color: white;  /* Change this to your desired color */
    padding: 0 5px;
    border-radius: 3px;
  }
  
  .app__aboutus-content_knife {
    width: 100px;
    height: 2px;
    background-color: white;
    margin: 20px auto;
  }
  